import React, { useState } from "react"
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  IconButton,
  Stack,
  Box,
  Divider,
} from "@chakra-ui/react"
import { BiShow, BiHide } from "react-icons/bi"
import { useCustomerLogin } from "@app/hooks/useCustomer"
import { graphql, useStaticQuery } from "gatsby"
import Link from "@app/components/Link"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"

const AccountLoginForm: React.FC = () => {
  const [show, setShow] = useState(false)
  const { handleSubmit, handleChange, data, loading, errors } = useCustomerLogin()
  const { urlResolver } = useRoutes()
  const {
    settings: { routes },
  } = useConfigContext()

  const { additionalContent } = useStaticQuery<GatsbyTypes.StaticLoginPageAdditionalContentQuery>(graphql`
    query StaticLoginPageAdditionalContent {
      additionalContent: sanityPageAccountLogin {
        additionalSubmitButton
      }
    }
  `)

  const { additionalSubmitButton } = additionalContent || {}

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8}>
      <Stack spacing={5}>
        <FormControl id="email" value={data?.email} onChange={handleChange} isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="email" type="email" />
        </FormControl>

        <FormControl id="password" value={data?.password} onChange={handleChange} isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input name="password" type={show ? "text" : "password"} />
            <InputRightElement>
              <IconButton
                variant="ghost"
                onClick={() => setShow(!show)}
                aria-label={`${show ? "Show" : "Hide"} password`}
                size="md"
                icon={show ? <BiHide /> : <BiShow />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        {errors?.length > 0 && (
          <FormControl id="error" isInvalid>
            {errors?.map(error => (
              <FormErrorMessage key={error.toString()}>
                Your email address and password don&apos;t match, please try again.
              </FormErrorMessage>
            ))}
          </FormControl>
        )}

        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {additionalSubmitButton}
        </Button>

        <Button variant="outline" as={Link} to={urlResolver({ title: "Forgot Password" }, routes.FORGOT).url}>
          Forgot Password
        </Button>

        <Divider />

        <Button variant="outline" as={Link} to={urlResolver({ title: "Create Account" }, routes.REGISTER).url}>
          Create Account
        </Button>
      </Stack>
    </Box>
  )
}

export default React.memo(AccountLoginForm)
